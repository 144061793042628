<template>
	<v-app>
		<v-dialog width="500" v-model="loginDialogVisible">
			<v-card >
				<v-card-title class="text-h5 grey lighten-2">
					Login
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="login" label="Login"></v-text-field>
					<v-text-field v-model="password" label="Password"></v-text-field>
					<v-btn dark color="primary" @click="loginUser ()">Login</v-btn>
					<p class="red--text">{{ message }}</p>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" width="400">
			<v-card color="rgba(255,255,255,0.5s)">
				<v-card-text style="font-family: 'arial';font-size:18px;color:black">
					<p style="padding-top:30px">{{ d.welcomeNewsLetter}}</p>
					<v-text-field v-model="user.firstName" :label="d.firstName"></v-text-field>
					<v-text-field v-model="user.lastName" :label="d.lastName"></v-text-field>
					<v-text-field v-model="user.email" :label="d.email"></v-text-field>
					<v-divider></v-divider>
					<p style="color:red;text-align:left;display:block">{{ message }}</p>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn dark color="primary" @click="saveUser(user)">{{ d.save }}</v-btn>
					</v-card-actions>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card color="grey lighten-4" flat tile>
			<v-app-bar app dense>
				<v-app-bar-nav-icon class="d-md-none" @click="drawer=true"></v-app-bar-nav-icon>
				<v-toolbar-title style="font-family: 'Great Vibes;font-size:32px" @click="home ()">Visit Gradoli</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn>
						<v-select :items="languages" v-model="language" style="width:50px">
							<template v-slot:selection="{ item }">
								<v-img :src="item.flag" width="20px" />
							</template>
							<template v-slot:item="{ item }">
								<v-img :src="item.flag" width="20px" />
							</template>
						</v-select>
					</v-btn>
				</v-toolbar-items>
				<v-toolbar-items  v-for="item in items" :key="item.name" class="d-none d-md-block">
					<v-menu  transition="fab" max-height="40vh" open-on-hover bottom >
						<template v-slot:activator="{ on, attrs }">
							<v-btn style="font-family:'Shadows Into Light', cursive;font-size:20px" v-bind="attrs" v-on="on">{{ d[item.title] }}</v-btn>
						</template>
						<v-list-item-group style="background:white">
							<v-list-item color="white" v-for="subMen in item.subMens" :key="subMen.name" @click="click(subMen.click)">
								<v-list-item-icon>
									<v-icon color="green lighten-2" v-text="subMen.icon"></v-icon>
								</v-list-item-icon>
								<v-list-item-content color="green lighten-2">
									<v-list-item-title>
										<v-list-item-title style="color:#66BB6A;" v-text="d[subMen.name]"></v-list-item-title>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-menu>
					
					<!-- d-none d-lg-block HIDE ON SCREENS SMALLER THAN LG -->
					<!-- <v-btn><v-icon>mdi-home</v-icon></v-btn> -->
				</v-toolbar-items>
			</v-app-bar>
		</v-card>
		<v-navigation-drawer class="d-md-none" v-model="drawer" absolute temporary>
			<v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.icon" no-action>
				<template v-slot:activator>
					<v-list-item-content v-text="item.title">
						<v-list-item-title >Menu</v-list-item-title>
					</v-list-item-content>
				</template>
				<v-list-item-group> 
					<v-list-item v-for="subMen in item.subMens" :key="subMen.name">
						<v-list-item-icon>
							<v-icon color="green lighten-2" v-text="subMen.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content color="green lighten-2">
							<v-list-item-title >
								<v-list-item-title style="color:#66BB6A" v-text="subMen.name"></v-list-item-title>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list-group>
		</v-navigation-drawer>
		<v-main>
			<router-view/>
		</v-main>
	</v-app>
</template>

<script>
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";
import firebaseApp from './firebase/init';
let db = getFirestore( firebaseApp );
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; //onAuthStateChanged
const auth = getAuth();
export default {
	name: 'App',
	data () {
		return {
			drawer: false,
			dialog: false,
			login: null,
			loginDialogVisible: false,
			message: null,
			password: null,
			user: {}
		}
	},
	computed: {
		d () {
			return this.$store.getters.lang_dict
		},
		items () {
			return this.$store.state.menu
		},
		language: {
            get () {
                return this.$store.state.language
            },
            set (newValue) {
                this.$store.commit('setLanguage', newValue)
            }
		},
		languages () {
			return this.$store.state.languages;
		},
	},
	async created () {
        let userLang = navigator.language || navigator.userLanguage;
        let lang;
        if ( userLang === 'nl') {
            // select the dutch object;
            lang = this.languages.filter(lang => {
                console.log(lang)
                return lang.abb === 'nl'
            })
		} else if ( userLang === 'it' || userLang === 'it-IT' || userLang == 'it-CH') {
			lang = this.languages.filter(lang => {
				console.log(lang)
				return lang.abb === 'it'
			})
		} else {
            // select the english object;
            lang = this.languages.filter(lang => {
                return lang.abb === 'en'
            })
        }
        this.language = lang[0];
	},
	methods: {
		click (action) {
			if ( action === 'register' ) {
				this.register();
			} else if ( action === 'login' ) {
				this.loginDialogVisible = true;
			} else if ( action === 'info' ) {
				this.$router.push( { name: 'Info'})
			}
		},
		home () {
			this.$router.push( {name: 'Home'});
		},
		async loginUser () {
            //let permissions = [];
            // Route users depending on token (admin, superadmin)
            if ( this.login && this.password) {
                try {
					await signInWithEmailAndPassword ( auth, this.login, this.password );
                    // await auth().signInWithEmailAndPassword(this.login, this.password);
                    this.message = "User logged in";
                }
                catch ( err ) {
                    this.message = err.message;
                }
				this.$router.push( { name: 'Menu' });
				this.loginDialogVisible = false;
            } else {
                this.message = "Please fill out login and password";
            }

        },
		register () {
			console.log('method register')
			this.dialog = true;
		},
		saveUser ( user ) {
			console.log(user)
			if ( user.firstName && user.lastName && user.email ) {
				updateDoc ( doc ( db, '/newsletter/newsletter_it'), 
				// db.collection('newsletter').doc('newsletter_it').update(
				{
					receiver: arrayUnion ( user )
				})
				.then(() => {
					console.log('got the doc')
					this.user = {};
				})
			} else { 
				this.message = this.d.fillOutAllFields;
			}
			
		}
	}
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
.menu {
	min-width:150px;
	width:150px;
	margin:auto;
}
</style>