// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import { enableIndexedDbPersistence } from "firebase/firestore"; 
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";

//import firestore from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBB-cMSFrV3qZUacDqNfDHkX0h1wBCy3I0",
    authDomain: "visitgradoli.firebaseapp.com",
    projectId: "visitgradoli",
    storageBucket: "visitgradoli.appspot.com",
    messagingSenderId: "325876103946",
    appId: "1:325876103946:web:6ee374c2cfb297ea7098f3",
    measurementId: "G-QWZFCD82WJ"
};

// firebase.initializeApp(firebaseConfig);
  
const app = initializeApp(firebaseConfig);
const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

enableIndexedDbPersistence(firestoreDb)
.catch((err) => {
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

  // Export firestore database
  export default app