<template>
	<div>
		<v-dialog v-model="loginModalVisible">
			<v-card width="500">
				<v-card-title class="text-h5 grey lighten-2">
					Login
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="login" label="Login"></v-text-field>
					<v-text-field v-model="password" label="Password"></v-text-field>
					<v-btn dark color="primary" @click="loginUser ()">Login</v-btn>
					<p>{{ message }}</p>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog width="400" v-model="eventDiaglog">
			<v-card>
				<v-card-title class="text-h5 grey lighten-2">{{ selectedEvent.data.title}}</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row style="padding-top:10px">
						<v-col cols="4">{{ d.place }}</v-col>
						<v-col>{{ selectedEvent.data.location }}</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">{{ d.date }}</v-col>
						<v-col>{{ selectedEvent.data.dateTxt }}</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">{{ d.time }}</v-col>
						<v-col>{{ selectedEvent.data.time }}</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">{{ d.description }}</v-col>
						<v-col>{{ selectedEvent.data.description_it }}</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">{{ d.contact }}</v-col>
						<v-col>{{ selectedEvent.data.contact }}</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT  -->
		<v-img src="../assets/header_gradoli.jpg" width="100%" ></v-img>
		<v-container style="width:100%;max-width:1024px;background:rgba(255,255,255,0.5)">
			<div style="margin-top:5vh;font-family:'arial';font-size:16px;text-align:left">
				<p><i>On behalf of the mayor, Attilio Mancini and the Artemisia foundation, I would like to welcome you to Gradoli, one of the undiscovered gems around Lake Bolsena. Gradoli is a small village with an important historical character and local traditions. The inhabitants are uncomplicated, friendly and accessible. The old town is dominated by the beautiful Palazzo Farnese. The village is known for its Aleatico wine DOC, quality olive oil and the famous Fagioli del Purgatorio. In short, in Gradoli you eat and drink well! Like Pope Paul III already wrote: “If you want to live forever, stay in Gradoli in the summer and in Canino during the winter!"</i></p>
				<p style="text-align:right"><i>
					The president of the ARTEMISIA Foundation
					<br>Bertie Franke
				</i></p>
			</div>
			<div><b>Upcoming events (click for more information)</b></div>

			<v-data-table :items="events" :headers="eventsHeaders" @click:row="eventMoreInfo" :items-per-page="-1"></v-data-table>
		</v-container>
	</div>
</template>

<script>
import { collection, getDocs, getFirestore } from "firebase/firestore"; //collection, getDoc, getDocs, doc, setDoc 
import firebaseApp from '../firebase/init';
let db = getFirestore( firebaseApp );
// import firebase from 'firebase'
export default {
	name: 'Home',
	components: {
	},
	data () {
		return {
			dialog: true,
			eventDiaglog: false,
			events: [],
			eventsHeaders: [
				{ text: 'Date', value: 'data.dateTxt' },
				{ text: 'Time', value: 'data.time'},
				{ text: "Event", value: 'data.title' },
				{ text: "Description", value: 'data.description_it' },
				{ text: 'Location', value: 'data.location' }
				
			], 
			login: null,
			loginModalVisible: false,
			message: null,
			password: null,
			selectedEvent: { data: {} },
			show1: false,
			show2: false,
			user: {},
		}
	},
	computed: {
		d () {
			return this.$store.getters.lang_dict
		},
		dbRef () {
            // return db.collection('Visitgradoli').doc('MenuStructure').collection('Activities').doc('Calendar').collection('Events')
			return '/Visitgradoli/MenuStructure/Activities/Calendar/Events/'
        }
	},
	async created () {
        // get events from database
        // let events = await this.dbRef.orderBy('data.date').limit(10).get()
		let events = await getDocs ( collection ( db, this.dbRef ) )
		console.log( events );
        for ( let event of events.docs ) {
			console.log(event.data());
			let eventLocal = {};
			eventLocal.data = event.data().data;
			// console.log(event.data().data.eventImage);
			// eventLocal.data.image = await this.$store.dispatch('imageUrlGet', event.data().data.eventImage)
			eventLocal.id = event.id
			console.log(eventLocal)
			this.events.push(eventLocal)
		}
		console.log(this.events)
		function compare( a, b ) {
			if ( a.data.date < b.data.date ){
				return -1;
			}
			if ( a.last_nom > b.last_nom ){
				return 1;
			}
			return 0;
		}
		this.events.sort( compare );
		// Today
		let today = new Date().toISOString().split('T')[0]
		this.events = this.events.filter(event => {
			return event.data.date >= today
		})
	},
	methods: {
		delay () {
			console.log('Method delay');
			console.log(this.$store.state.lang_dict);
		},
		eventMoreInfo (event) {
			this.selectedEvent = event;
			this.eventDiaglog = true;
		}
	}
}
</script>

<style>
v-col .left {
	width: 30%
}

</style>