import Vue from 'vue'
import Vuex from 'vuex'
// import firebase from 'firebase'
// import firebaseApp from '../firebase/init';
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
const storage = getStorage();

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		dictionary: { 
			activities: { en: 'Activities', nl: 'Activiteiten', it: 'Attività'},
			bicycle: { en: 'Bicycle', nl: 'Fietsen', it: 'Bici' },
			calendar: { en: 'Calendar', nl: 'Agenda', it: 'Calendario' },
			carTour: { en: 'Car tour', nl: 'Autorit', it: 'Gite in auto' },
			welcomeNewsLetter: { 
				en: 'Soon `Visit Gradoli` will be here, with information on the village and activities. You can already register to receive our newsletter so you will be informed on activities.',
				it: 'Qui nasce `Visit Gradoli`, con informazioni su paese e attività. È già possibile di iscriversi al nostro newsletter per essere informato sulle attività.',
				nl: 'Hier komt `Visit Gradoli`, met informatie over dorp en activiteiten. U kunt zich al inschrijven voor onze nieuwsbrief om op de hoogte te blijven van activiteiten.'
			},
			contact: { en: 'Contact', nl: 'Contact', it: 'Contatto'},
			date: { en: 'Date', nl: 'Datum', it: 'Data'},
			description: { en: 'Description', nl: 'Beschrijving', it: 'Descrizione' },
			eatAndDrink: { en: 'Eat, Drink & Shops', nl: 'Eten, Drinken & Winkels', it: 'Mangiare, Bere & Negozi' },
			editEvent: { en: 'Edit event', nl: 'Eventement aanpassen', it: 'Modifica evento' },
			email: { en: 'E-mail', nl: 'E-mail', it: 'E-mail' },
			events: { en: 'Events', nl: 'Evenementen', it: 'Eventi'},
			firstName: { en: 'Firstname', nl: 'Voornaam', it: 'Nome' },
			fillOutAllFields: { en: 'Please fill out all fields', nl: 'Vult u alle velden in alstublieft.', it: 'Si prega di compilare tutti i campi' },
			gallery: { en: 'Gallery', nl: 'Gallerij', it: 'Galleria' },
			gradoli: { en: 'Gradoli', nl: 'Gradoli', it: 'Gradoli' },
			hike: { en: 'Hikes', nl: 'Wandelen', it: 'Passeggiate' },
			i: { en: 'ⓘ', nl: 'ⓘ', it: 'ⓘ'},
			lastName: { en: 'Lastname', nl: 'Achternaam', it: 'Cognome' },
			location: { en: 'Location', nl: 'Locatie', it: 'Posto' },
			menu: { en: 'Menu', nl: 'Menu', it: 'Menù'},
			moreInfo: { en: 'More info', nl: 'Meer info', it: 'Più info'},
			museums: { en: 'Museums', nl: 'Musea', it: 'Musei'},
			name: { en: 'Name', nl: 'Naam', it: 'Nome'},
			nature: { en: 'Nature', nl: 'Natuur', it: 'Natura' },
			place: { en: 'Place', nl: 'Plaats', it: 'Posto' },
			places: { en: 'Places', nl: 'Plaatsen', it: 'Posti'},
			price: { en: 'Price', nl: 'Prijs', it: 'Prezzo' },
			save: { en: 'save', nl: 'opslaan', it: 'salva' },
			surroundingTowns: { en: 'Surrounding Towns', nl: 'Omliggende plaatsen', it: 'Città circostanti' },
			swim: { en: 'Swim', nl: 'Zwemmen', it: 'Bagno' },
			sleep: { en: 'Sleep', nl: 'Slapen', it: 'Dormire'},
			time: { en: 'Time', nl: 'Tijd', it: 'Orario' },
				
		},
		language: { name: 'english', abb: 'en', image: 'https://firebasestorage.googleapis.com/v0/b/building-file-bb3ab.appspot.com/o/sys_img%2Fuk_1280x768.png?alt=media&token=df7b0ba2-3133-4166-9f93-2090fbabae6b'},
		languages: [
			{ name: 'English', abb: 'en', flag: 'https://firebasestorage.googleapis.com/v0/b/building-file-bb3ab.appspot.com/o/sys_img%2Fuk_1280x768.png?alt=media&token=df7b0ba2-3133-4166-9f93-2090fbabae6b'},
			{ name: 'Nederlands', abb: 'nl', flag: 'https://firebasestorage.googleapis.com/v0/b/building-file-bb3ab.appspot.com/o/sys_img%2Fnl_1280x768.png?alt=media&token=07727c72-1638-4424-a987-3348c2b21c5a'},
			{ name: 'Italiano', abb: 'it', flag: 'https://firebasestorage.googleapis.com/v0/b/building-file-bb3ab.appspot.com/o/sys_img%2Fit_1280x768.jpg?alt=media&token=88c64a13-9842-4530-9f41-fe206bdb1327' },
		],
		menu: [
			// { title: 'places', icon: 'mdi-map-marker', subMens: [ 
				// { name: 'gradoli', icon: 'mdi-home-city-outline' }, 
				// { name: 'museums', icon: 'mdi-bank' }, 
				// { name: 'surroundingTowns', icon: 'mdi-city-variant-outline' },  
				// { name: 'nature', icon: 'mdi-nature' } 
			// ] },
			// { title: 'activities', icon: 'mdi-run', subMens: [ 
				// { name: 'hike', icon: 'mdi-hiking' }, 
				// { name: 'bicycle', icon: 'mdi-bicycle' }, 
				// { name: 'swim', icon: 'mdi-swim' }, 
				// { name: 'carTour', icon: 'mdi-car' }, 
				// { name: 'calendar', icon: 'mdi-calendar' } ] },
			{ title: 'eatAndDrink', icon: 'mdi-beer', subMens: [ 
				{ name: 'Bar', icon: 'mdi-glass-cocktail' }, 
				{ name: 'Restaurant', icon: 'mdi-silverware' }, 
				{ name: 'Shops', icon: 'mdi-cart-outline' } ] },
			{ title: 'sleep', icon: 'mdi-sleep', subMens: [ 
				// { name: 'Hotels', icon: 'mdi-bunk-bed' }, 
				{ name: 'Campings', icon: 'mdi-tent' }, 
				// { name: 'B&B`s', icon: 'mdi-bed'}, 
				{ name: 'Homes / appartments', icon: 'mdi-home' } ] },
			// { title: 'gallery', icon: 'mdi-camera', subMens: [ 
				// { name: 'Gradoli', icon: 'mdi-home-city-outline' }, 
				// { name: 'Panorama', icon: 'mdi-panorama' }, 
				// { name: 'Nature', icon: 'mdi-nature' } ] },
			{ title: 'i', icon: '', subMens: [ 
				{ name: 'Contact', icon: 'mdi-phone' }, 
				// { name: 'About us', icon: 'mdi-information', click: 'info' },
				// { name: 'Register', icon: 'mdi-register', click: 'register' },
				{ name: 'Login', icon: 'mdi-login', click: 'login' }
			] }
		]
	},
	mutations: {
		setLanguage( state, val) {
			state.language = val;
		}
	},
	actions: {
		async fileUpload ( {state}, file) {
			console.log(state.language);
			var blob = file.slice(0, file.size, file.type);
			// Replaces spaces, /, \ in file name by underscores
			let fileName = file.name.split(' ').join('_');
			fileName = fileName.split('/').join('_');
			fileName = fileName.split('\\').join('_');
			// set file extension to lowercase
			// first split the filename on periods
            const fileNameArray = fileName.split('.');
			// the extension is the last element in the array
			let ext = fileNameArray[fileNameArray.length-1];
			ext = '.'+ext.toLowerCase();
			console.log("ext:"+ext);
			fileNameArray[fileNameArray.length-1] = ext;
			fileName = fileNameArray.join('');
			// Make a copy of the file so that the name can be changed/set
			const newFile = new File([blob], fileName, {type: file.type});
			// upload file to storage bucket
			// let storageRef = firebase.storage().ref('/gradoli/'+fileName);
			let storageRef = ref ( storage, '/gradoli/'+ fileName );
			// put the selected file
			let fileSpecs = {};
			// filespecs is for returning to the component
			fileSpecs.url = uploadBytes ( storageRef (newFile) );
			fileSpecs.fileName = fileName;
			// if an image, add 1280x768 to the filename
			if ( ext =='.jpg' || ext == '.jpeg' || ext == '.png' || ext == '.gif' || ext == '.tiff' || ext == '.tif') {
				ext = '_1280x768'+ext;
				fileNameArray[fileNameArray.length-1] = ext;
				fileSpecs.fileName = fileNameArray.join('');
			}
			// return object
			return fileSpecs;
		},
		async imageUrlGet( { state }, file ) {
			let imageRef;
			console.log( state );
			imageRef = 'gradoli/' + file;
            // Set the reference for the file
			let storageRef = ref ( storage, imageRef );
            let url = await getDownloadURL( storageRef );
            return url;
        },
	},
	modules: {
	},
	getters: {
		lang_dict: (state) => {
			let language = state.language.abb;
			let lang_dict = {};
			// cycle dictionary and return an object with all entries in the currently selected language.
			for (const [key, value] of Object.entries(state.dictionary)) {
				lang_dict[ key ] = value[language];
			}
			return lang_dict
		},
	}
})
